"use client";
import { createContext, PropsWithChildren, useEffect, useState } from "react";
import { StoreApi, useStore } from "zustand";
import { OrgStore } from "./store/types";
import { createOrgStore } from "./store";
import { useAuth } from "@/shared/hooks/useAuth";

export const OrgContext = createContext<StoreApi<OrgStore> | undefined>(
  undefined
);

export default function OrgContextProvider({ children }: PropsWithChildren) {
  const { allOrgs } = useAuth();

  const [store] = useState(createOrgStore);
  const {
    allOrgs: storedOrgs,
    setAllOrgs,
    setIsLoading,
    setInitialized,
  } = useStore(store);

  useEffect(() => {
    if (allOrgs.length > 0) {
      setAllOrgs(allOrgs);
    }
    setIsLoading(!storedOrgs);
    setInitialized(true);
  }, [allOrgs, storedOrgs, setAllOrgs, setIsLoading, setInitialized]);

  return <OrgContext.Provider value={store}>{children}</OrgContext.Provider>;
}

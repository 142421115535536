import { useContext } from "react";
import { OrgContext } from "@/shared/contexts/OrgContext";
import { OrgStore } from "@/shared/contexts/OrgContext/store/types";
import { useStore } from "zustand";

export function useOrgStore<T>(selector: (state: OrgStore) => T) {
  const context = useContext(OrgContext);

  if (!context) {
    throw new Error("CountContext.Provider is missing");
  }

  return useStore(context, selector);
}

import { create } from "zustand";
import { persist } from "zustand/middleware";
import { OrgStore } from "./types";
import { UUID } from "@screencloud/uuid";
import { ORG_STORE_KEY } from "@/configs/constants";
import { GetAuthSession200AllOrgsItem } from "@/shared/api/models";

export const createOrgStore = () =>
  create<OrgStore>()(
    persist(
      (set) => ({
        allOrgs: null,
        isLoading: true,
        currentOrg: null,
        initialized: false,

        setInitialized: (initialized: boolean) => set({ initialized }),
        setCurrentOrg: (orgId: UUID) => set({ currentOrg: orgId }),
        setIsLoading: (isLoading: boolean) => set({ isLoading }),
        setAllOrgs: (orgs: GetAuthSession200AllOrgsItem[]) =>
          set({ allOrgs: orgs }),
      }),
      {
        name: ORG_STORE_KEY,
        partialize: (state) => ({
          allOrgs: state.allOrgs,
          currentOrg: state.currentOrg,
        }),
      }
    )
  );

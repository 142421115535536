"use client";
import { useAuth } from "@/shared/hooks/useAuth";
import { useOrgStore } from "@/shared/hooks/useOrgStore";
import { createContext, ReactNode } from "react";
import { LoadingScreen } from "./components/LoadingScreen";

interface GlobalLoadingContextType {
  isLoading: boolean;
}

export const GlobalLoadingContext = createContext<GlobalLoadingContextType>({
  isLoading: true,
});

export function GlobalLoadingProvider({ children }: { children: ReactNode }) {
  const isOrgInitialized = useOrgStore((state) => state.initialized);
  const { isLoading: isAuthLoading } = useAuth();

  const isLoading = !isOrgInitialized || isAuthLoading;

  return (
    <GlobalLoadingContext.Provider value={{ isLoading }}>
      {isLoading ? <LoadingScreen /> : children}
    </GlobalLoadingContext.Provider>
  );
}
